<template>
  <Exploration
    :categoriesURL="apiURLs.EXPLORE.PROJECTS.CATEGORIES"
    :baseExploreURL="apiURLs.EXPLORE.PROJECTS.EXPLORE"
    :locationsURL="apiURLs.EXPLORE.PROJECTS.LOCATIONS"
    :filters="projectFilters"
  />
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

import Exploration from '@/components/explore/Exploration.vue'
import { IProjectExploreFilters } from '@/types/projects'
import { API_URLS } from '@/utils/helpers'

@Component({
  components: {
    Exploration,
  },
  name: 'project-explore',
})
export default class ProjectExplore extends Vue {
  projectFilters: IProjectExploreFilters = {
    searchString: '',
    hideFullyFunded: true,
    hideExpired: true,
    categories: [],
    sortOrder: null,
    cmsFilter: null,
  }

  get apiURLs(): typeof API_URLS {
    return API_URLS
  }
}
</script>
