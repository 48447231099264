import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/styles/index.scss'

import VueRouter from 'vue-router'
import VueWait from 'vue-wait'

import store from '@/store'
import { setUpVue } from '@/utils/setup'
import App from '@/views/DocumentAdministration.vue'

import router from './routes'

const Vue = setUpVue()

Vue.use(VueRouter)

new Vue({
  store,
  router,
  wait: new VueWait({ useVuex: true }),
  render: (h) => h(App),
}).$mount('#app')
